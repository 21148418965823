
import MenuComponent from '@/components/MenuComponent.vue';
import dateFormat from '@/helpers/DateFormat.helper';
import { patientService } from '@/services/PatientService';
import { ServiceError } from '@/services/util/ServiceError';
import { computed, defineComponent, onMounted, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute } from 'vue-router';

export default defineComponent({
  components: { MenuComponent },

  setup() {
    const { t } = useI18n();
    const route = useRoute();
    const patientId = Array.isArray(route.params.patientId) ? route.params.patientId[0] : route.params.patientId;
    const patient = ref();
    const age = computed(() => dateFormat.calculateAge(patient.value.birthdate));
    const avatarPlaceholder = window.location.origin + '/assets/layout/images/avatar.svg';
    const accountsAllowFollowUp = ref(process.env.VUE_APP_ACCOUNTS_ALLOW_FOLLOW_APP_SECTION);

    const menu = computed(() => {
      const commonMenuItems = [
        { separator: true },
        {
          label: t('breadcrumb.patientData'),
          icon: 'icon-id_card',
          to: `/patient/${patientId}/patient-data/medical-history`,
        },
        {
          label: t('breadcrumb.followUp'),
          icon: 'icon-heart',
          to: `/patient/${patientId}/follow-up/sleep`,
        },
        {
          label: t('breadcrumb.medicalTests'),
          icon: 'icon-bar_chart ',
          to: `/patient/${patientId}/medical-tests`,
        },
        {
          label: t('breadcrumb.nutrition'),
          icon: 'icon-nutrition',
          class: 'drop-down',
          items: [
            {
              label: t('breadcrumb.plans'),
              to: `/patient/${patientId}/nutrition/plans`,
            },
            {
              label: t('breadcrumb.menus'),
              to: `/patient/${patientId}/nutrition/menus`,
            },
          ],
        },
        {
          label: t('breadcrumb.supplements'),
          icon: 'icon-supplements',
          to: `/patient/${patientId}/supplements`,
        },
        {
          label: t('breadcrumb.notes'),
          icon: 'icon-note',
          to: `/patient/${patientId}/notes`,
        },
        {
          label: t('breadcrumb.configuration'),
          icon: 'icon-settings',
          to: `/patient/${patientId}/configuration/patientData`,
        },
      ];

      return commonMenuItems;
    });

    onMounted(async () => {
      const result = await patientService.find(patientId);
      if (!(result instanceof ServiceError)) {
        patient.value = result;
      }
    });

    const onPatientChanged = async () => {
      const result = await patientService.find(patientId);
      if (!(result instanceof ServiceError)) {
        patient.value = result;
      }
    };
    return { menu, patient, age, avatarPlaceholder, onPatientChanged, accountsAllowFollowUp };
  },
});
