import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, Fragment as _Fragment, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "p-grid" }
const _hoisted_2 = { class: "layout-sidebar" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = {
  key: 1,
  class: "patient-detais"
}
const _hoisted_5 = { "aria-labelledby": "secondary-navigation" }
const _hoisted_6 = { class: "layout-menu-container layout-menu" }
const _hoisted_7 = { class: "p-col p-col-main" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Avatar = _resolveComponent("Avatar")!
  const _component_MenuComponent = _resolveComponent("MenuComponent")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_Avatar, {
        image: _ctx.avatarPlaceholder,
        size: "xlarge",
        shape: "circle"
      }, null, 8, ["image"]),
      (_ctx.patient)
        ? (_openBlock(), _createElementBlock("p", _hoisted_3, [
            _createElementVNode("strong", null, _toDisplayString(_ctx.patient.name) + " " + _toDisplayString(_ctx.patient.surname), 1)
          ]))
        : _createCommentVNode("", true),
      (_ctx.patient)
        ? (_openBlock(), _createElementBlock("p", _hoisted_4, [
            (_ctx.patient.gender)
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                  _createTextVNode(_toDisplayString(_ctx.$t(`user.${_ctx.patient.gender}`)), 1)
                ], 64))
              : _createCommentVNode("", true),
            (_ctx.patient.gender && _ctx.age)
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                  _createTextVNode(" - ")
                ], 64))
              : _createCommentVNode("", true),
            (_ctx.age)
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
                  _createTextVNode(_toDisplayString(!_ctx.age || isNaN(_ctx.age) ? '' : _ctx.$t('patient.yearsOld', { number: `${_ctx.age}` })), 1)
                ], 64))
              : _createCommentVNode("", true)
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("nav", _hoisted_5, [
        _createElementVNode("div", _hoisted_6, [
          _createVNode(_component_MenuComponent, {
            items: _ctx.menu,
            "layout-mode": "static",
            "menu-active": false,
            "parent-menu-item-active": true,
            "mobile-menu-active": false,
            root: false
          }, null, 8, ["items"])
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_7, [
      (_ctx.patient)
        ? (_openBlock(), _createBlock(_component_router_view, {
            key: 0,
            patient: _ctx.patient,
            onPatientChanged: _ctx.onPatientChanged
          }, null, 8, ["patient", "onPatientChanged"]))
        : _createCommentVNode("", true)
    ])
  ]))
}